import { defineComponent } from "vue";

import Hero from "@/components/hero/index.vue";
import SummitLink from "@/components/summit-link/index.vue";
import heroBg from "../../assets/images/about-us-bg.png";

export default defineComponent({
  name: "Summit Report",
  components: { SummitLink, Hero },
  setup() {
    const heroSlide = [
      {
        title: "Summit Report",
        image: heroBg,
      },
    ];

    return {
      heroSlide,
    };
  },
  data() {
    return {

      // filesrcs2: [
      //   { title: 'BUSINESS PREMISES APPROVAL', src: 'https://res.cloudinary.com/dev-burna/raw/upload/v1668268754/images/corporations/business-premises-approval' },
      //   { title: 'FEES AND LEVIES', src: 'https://res.cloudinary.com/dev-burna/raw/upload/v1668269161/images/corporations/fees-and-levies' },
      //   { title: 'BUILDING PERMIT', src: 'https://res.cloudinary.com/dev-burna/raw/upload/v1668269888/images/corporations/building-permit' },
      //   { title: 'RofO PROCESSING', src: 'https://res.cloudinary.com/dev-burna/raw/upload/v1668269961/images/corporations/rofo-processing' },
      //   { title: 'CofO PROCESSING', src: 'https://res.cloudinary.com/dev-burna/raw/upload/v1668270014/images/corporations/cofo-processing' },
      //   { title: 'DATA/STATISTICS FOR CofO', src: 'https://res.cloudinary.com/dev-burna/raw/upload/v1668270348/images/corporations/data-statistics-for-cofo' },
      //   { title: 'VEHICLE INSPECTION', src: 'https://res.cloudinary.com/dev-burna/raw/upload/v1668270396/images/corporations/vehicle-inspection' },
      //   { title: 'INVESTMENT INCENTIVE INVENTORY', src: 'https://res.cloudinary.com/dev-burna/raw/upload/v1668270525/images/corporations/investment-incentive-inventory' },
      //   { title: 'WASTE MANAGEMENT SERVICES', src: 'https://res.cloudinary.com/dev-burna/raw/upload/v1668270602/images/corporations/waste-management-services' },
      //   { title: 'FOREST PRODUCE PERMIT', src: 'https://res.cloudinary.com/dev-burna/raw/upload/v1668270661/images/corporations/forest-produce-permit' },
      // ],
      
      filesrcs: [
        { title: 'Procedure for obtaining RofO', src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-obtaining-RofO' },
        { title: 'Procedure for obtaining CofO', src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-obtaining-CofO' },
        { title: 'Data/Statistics of CofO Issued', src: 'https://api.nasida.na.gov.ng/downloads/Data-for-CofO' },
        { title: 'Procedure For obtaining Building Permit', src: 'https://api.nasida.na.gov.ng/downloads/PROCEDURE-FOR-OBTAING-BUILDING-PERMIT' },
        { title: 'Investment Incentive Inventory', src: 'https://api.nasida.na.gov.ng/downloads/Nasarawa-State-Investment-Incentive-Inventory' },
        { title: 'Procedures for Business Premises Registration', src: 'https://api.nasida.na.gov.ng/downloads/Business-Premises' },
        { title: 'Procedures for Obtaining approval for Signage & Advertisement Approval', src: 'https://api.nasida.na.gov.ng/downloads/PROCEDURE-FOR-OBTAINING-APPROVAL-FOR-SIGNAGE' },
        { title: 'Procedures for Obtaining Water Connection Approval', src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-Obtaining-Approval-to-Connect-to-Public-Water' },
        { title: 'Procedures for Obtaining Vehicle Inspection Approval', src: 'https://api.nasida.na.gov.ng/downloads/The-Procedure-of-Obtaining-Vehicle-Inspection-Approval-for-Businesses-Fleet-in-Nasarawa-State' },
        { title: 'Procedures for Obtaining Forest Produce Approval', src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-Obtaining-Forest-Produce-Permit-in-Nasarawa-State' },
        { title: "Procedure for Obtaining Waste Management Approval", src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-Obtaining-Waste-Management-Services-Approval' },
        { title: 'State Official Fees and Levies', src: 'https://api.nasida.na.gov.ng/downloads/NASARAWA-STATE-FEES-AND-LEVIES' },
        
        
        // old files
        // { title: 'Procedures for Obtaining Waste Management Service Approval', src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-Obtaining-Waste-Management-Services-Approval-in-Nasarawa-State' },
        // { title: 'FEES AND LEVIES', src: 'https://api.nasida.na.gov.ng/downloads/FEES-AND-LEVIES' },
        // { title: 'RofO PROCESSING', src: 'https://api.nasida.na.gov.ng/downloads/C-of-O-PROCESS' },
        // { title: 'CofO PROCESSING', src: 'https://api.nasida.na.gov.ng/downloads/R-of-O-Process-manual.' },
        // { title: 'Procedures for Obtaining Building Permit', src: 'https://api.nasida.na.gov.ng/downloads/BUILDING-PERMIT' },
        // { title: 'Data/Statistics For CoFo', src: 'https://api.nasida.na.gov.ng/downloads/Data-Statistics' },
        // { title: 'Investment Incentive Inventory', src: 'https://api.nasida.na.gov.ng/downloads/NASARAWA-State-Investment-Incentives-Inventory' },
        
        
        // new files
        // { title: '2022 Nasarawa State Debt Sustainability Analysis DSA-DMS Report', src: 'https://api.nasida.na.gov.ng/downloads/The-2022-Nasarawa-State-Debt-Sustainability-Analysis-DSA-DMS-Report' },
        // { title: 'Procedure for Obtaining Business Premises Permit', src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-Obtaining-Business-Premises-Permit' },
        // { title: 'Procedure for Obtaining Forest Produce Permit', src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-Obtaining-Forest-Produce-Permit' },
        // { title: 'Procedure for Property Registration', src: 'https://api.nasida.na.gov.ng/downloads/PROCEDURE-FOR-PROPERTY-REGISTRATION' },
        // { title: 'The Procedure of Obtaining Vehicle Inspection Approval', src: 'https://api.nasida.na.gov.ng/downloads/The-Procedure-of-Obtaining-Vehicle-Inspection-Approval' },
        

      ],
    }
  }, 

  methods: {
    goto(url) {
      window.open(url);
    },
  },


});
